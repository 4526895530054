import {
  flockTheme,
  LibraryThemeProvider,
  SendPasswordResetForm,
} from '@flock/shared-ui'
import { ThemeProvider } from '@mui/system'
import React from 'react'

const SendPasswordResetPage = () => (
  <ThemeProvider theme={flockTheme}>
    <LibraryThemeProvider>
      <SendPasswordResetForm />
    </LibraryThemeProvider>
  </ThemeProvider>
)

export default SendPasswordResetPage
