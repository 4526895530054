import { useFirebaseAuth } from '@flock/utils'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useState } from 'react'
import {
  SendPasswordResetFormProps,
  SendPasswordResetFormPresentationalProps,
  SendPasswordResetFormResult,
} from './sendPasswordResetFormTypes'

const useSendPasswordResetForm: (
  props: SendPasswordResetFormProps
) => SendPasswordResetFormPresentationalProps = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [showEmailSent, setShowEmailSent] = useState(false)

  const { auth } = useFirebaseAuth()

  const onResetPassword = async ({ email }: SendPasswordResetFormResult) => {
    setLoading(true)
    try {
      await sendPasswordResetEmail(auth, email)
      setLoading(false)
      setShowEmailSent(true)
    } catch (e: any) {
      setLoading(false)
      if (e.message && e.message.includes('user-not-found')) {
        setError('Invalid email')
      } else {
        setError('A network error has occurred.')
      }
    }
  }

  return {
    loading,
    error,
    showEmailSent,
    onResetPassword,
  }
}

export default useSendPasswordResetForm
