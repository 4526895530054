import React from 'react'
import { Box, Typography } from '@mui/material'
import useSendPasswordResetForm from './useSendPasswordResetForm'
import { SendPasswordResetFormProps } from './sendPasswordResetFormTypes'
import flockTheme from '../../theme/theme'
import GridForm from '../GridForm/GridForm'
import { InputType } from '../GridForm/InputTypes'

const SendPasswordResetForm = (props: SendPasswordResetFormProps) => {
  const { loading, error, showEmailSent, onResetPassword } =
    useSendPasswordResetForm(props)

  return (
    <Box
      display="flex"
      width="100%"
      height="100vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: flockTheme.palette.green0.main,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="16px"
        maxWidth="400px"
        gap="16px"
      >
        <Box id="recaptcha-container-id" />
        <Typography variant="h3">Password Reset</Typography>
        {showEmailSent ? (
          <>
            <Typography variant="p2">
              We&apos;ve sent you an email with a link to reset your password.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="p2">
              Enter your email and we&apos;ll send you a link to reset your
              password.
            </Typography>
            <GridForm
              loading={loading}
              onSubmit={onResetPassword}
              gridProps={{
                gap: '16px',
              }}
              onBack={() => {
                window.location.href = '/login'
              }}
              inputConfigs={[
                {
                  name: 'email',
                  type: InputType.Text,
                  required: true,
                  props: {
                    label: 'Email',
                    type: 'email',
                    fullWidth: true,
                  },
                },
                {
                  name: 'errorMsg',
                  type: InputType.CustomComponent,
                  props: {
                    component: (
                      <>
                        {error && (
                          <Typography
                            sx={{ fontFamily: 'Outfit' }}
                            variant="body2"
                            color="error"
                          >
                            {error}
                          </Typography>
                        )}
                      </>
                    ),
                  },
                },
              ]}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

export default SendPasswordResetForm
